.timer{
    margin : 1rem 0;
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.digits{
                    font-family: Verdana, Geneva, Tahoma, sans-serif;
                    font-size: 20vw;
                    color:  #f5f5f5;
    -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }

.mili-sec{
    color:  #e42a2a;
}
