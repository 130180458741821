.App{
  background-color: rgb(0, 0, 0);
  width: 100vw;
/*  height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  height: calc(100% + (var(--safe-area-inset-top) + var(--safe-area-inset-bottom)));
}
